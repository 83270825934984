export function getDeviceInfo() {
  return new Promise((resolve, reject) => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      reject({
        error_code: "not_supported",
        error_msg: "Browser not supported",
        error_desc:
          "Please use another browser to use video call functionality",
      });
    }

    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        resolve({
          audioInputDevices: devices.filter(
            (device) => device.kind === "audioinput"
          ),
          videoInputDevices: devices.filter(
            (device) => device.kind === "videoinput"
          ),
          audioOutputDevices: devices.filter(
            (device) => device.kind === "audiooutput"
          ),
          hasAudioInputDevices: devices.some(
            (device) => device.kind === "audioinput"
          ),
          hasVideoInputDevices: devices.some(
            (device) => device.kind === "videoinput"
          ),
        });
      })
      .catch(() => {
        reject({
          error_code: "unknown_error",
          error_msg: "Something went wrong",
          error_desc: "Please try to refresh the page and try again",
        });
      });
  });
}

export function hasGetUserMedia() {
  return !!(
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia
  );
}

export function filterDevices(devices) {
  return devices.filter((device) => !!device.deviceId);
}
