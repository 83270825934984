import { render, staticRenderFns } from "./VideoCall.vue?vue&type=template&id=6d0a0dfc&scoped=true&"
import script from "./VideoCall.vue?vue&type=script&lang=js&"
export * from "./VideoCall.vue?vue&type=script&lang=js&"
import style0 from "./VideoCall.vue?vue&type=style&index=0&id=6d0a0dfc&lang=scss&scoped=true&"
import style1 from "./VideoCall.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d0a0dfc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VDivider,VIcon,VOverlay,VProgressCircular,VSelect,VTooltip})
